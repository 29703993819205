'use client';

import { convertDtoToUpsertTableRow } from '@company/common/lib';
import { TableRowDataType, TableRowLinkedRowType, ViewState } from '@company/common/types';
import { createZustandContext } from '@stores/create';
import { TableDtoUi, TableRowDtoUi } from '@typings/table';
import { create } from 'zustand';
import {
  modifyTableRowsAction,
  updateTableViewStateAction,
  approveChangeProposalAction,
  declineChangeProposalAction
} from '../actions';
import { DeleteTableRow, UpdateTableRow } from '../types';
import { toaster } from '@company/ui/components';

interface InitialState {
  table: TableDtoUi;
}

interface TableStore extends InitialState {
  isViewingChangeProposalDiff: boolean;
  rowIdToRow: Record<string, TableRowDtoUi>;
  updateRows: (rows: UpdateTableRow[], options?: { shouldSaveChanges?: boolean }) => void;
  deleteRows: (rows: DeleteTableRow[]) => void;
  clearRowValues: (cells: { rowId: string; fieldId: string }[]) => void;
  getRowById: (rowId: string) => TableRowDtoUi;
  getValue: (rowId: string, fieldId: string) => TableRowDataType | null;
  viewChangeProposalDiff: () => void;
  hideChangeProposalDiff: () => void;
  updateActiveViewState: (
    newState: Partial<ViewState>,
    options?: { shouldSaveToDb?: boolean }
  ) => void;
  approveProposedChanges: (rowIds: string[]) => Promise<void>;
  declineProposedChanges: (rowIds: string[]) => Promise<void>;
}

export const [TableStoreProvider, useTableStore] = createZustandContext<InitialState, TableStore>(
  initial =>
    create<TableStore>((set, get) => ({
      table: initial.table,
      rowIdToRow: initial.table.rows.reduce(
        (acc, row) => {
          acc[row.id] = row;
          return acc;
        },
        {} as Record<string, TableRowDtoUi>
      ),
      isViewingChangeProposalDiff: false,
      updateRows: (updatedRows, options) => {
        set(state => {
          const newRows = state.table.rows.map(row => {
            const update = updatedRows.find(u => u.id === row.id);
            return update ? { ...row, ...update } : row;
          });
          const newTable = { ...state.table, rows: newRows };
          return {
            ...state,
            table: newTable,
            rowIdToRow: newRows.reduce(
              (acc, row) => {
                acc[row.id] = row;
                return acc;
              },
              {} as Record<string, TableRowDtoUi>
            )
          };
        });

        if (options?.shouldSaveChanges === undefined || options?.shouldSaveChanges) {
          void modifyTableRowsAction({
            tableId: get().table.id,
            toUpsertRows: updatedRows.map(convertDtoToUpsertTableRow),
            toDeleteRows: []
          });
        }
      },
      deleteRows: rowsToDelete => {
        set(state => {
          const idsToDelete = new Set(rowsToDelete.map(r => r.id));
          const newRows = state.table.rows.filter(row => !idsToDelete.has(row.id));
          const newTable = { ...state.table, rows: newRows };
          return {
            table: newTable,
            rows: newRows,
            rowIdToRow: newRows.reduce(
              (acc, row) => {
                acc[row.id] = row;
                return acc;
              },
              {} as Record<string, TableRowDtoUi>
            )
          };
        });
      },
      // Improved getRowById uses a cached dictionary for O(1) lookups
      getRowById: rowId => {
        return get().rowIdToRow[rowId]!;
      },
      clearRowValues: cells => {
        const editableCells = cells.filter(cell => {
          const field = get().table.fields.find(f => f.id === cell.fieldId);
          return field?.isEditable;
        });
        const fieldIdToValueMap = editableCells.reduce(
          (acc, cell) => {
            const field = get().table.fields.find(f => f.id === cell.fieldId);
            if (!field?.isEditable) {
              return acc;
            }
            if (field?.type === 'LINKED_ROW') {
              acc[cell.fieldId] = { linkedRows: [], recommendationStatus: 'COMPLETED' };
            } else {
              acc[cell.fieldId] = null;
            }
            return acc;
          },
          {} as Record<string, TableRowDataType | null>
        );

        get().updateRows(
          editableCells.map(cell => ({
            id: cell.rowId,
            [cell.fieldId]: fieldIdToValueMap[cell.fieldId]!
          }))
        );
      },
      getValue: (rowId, fieldId) => {
        const field = get().table.fields.find(f => f.id === fieldId);
        const value = get().table.rows.find(row => row.id === rowId)?.[fieldId] ?? null;
        if (field?.type === 'LINKED_ROW' && value === null) {
          return { linkedRows: [], recommendationStatus: 'COMPLETED' } as TableRowLinkedRowType;
        }
        return value;
      },
      viewChangeProposalDiff: () => {
        set(prev => ({ ...prev, isViewingChangeProposalDiff: true }));
      },
      hideChangeProposalDiff: () => {
        set(prev => ({ ...prev, isViewingChangeProposalDiff: false }));
      },
      updateActiveViewState: (
        newState: Partial<ViewState>,
        options?: { shouldSaveToDb?: boolean }
      ) => {
        const activeView = get().table.activeView;
        const updatedState: ViewState = {
          ...activeView.state,
          ...newState
        };

        set(prev => ({
          ...prev,
          table: { ...prev.table, activeView: { ...prev.table.activeView, state: updatedState } }
        }));

        if (options?.shouldSaveToDb === undefined || options?.shouldSaveToDb) {
          void updateTableViewStateAction({ state: updatedState, viewId: activeView.id });
        }
      },
      approveProposedChanges: async (rowIds: string[]) => {
        const table = get().table;
        if (!table.changeProposalId) {
          return;
        }
        const rowsWithProposedChanges = table.rows.filter(row => row.proposedChange !== null);
        await approveChangeProposalAction({
          tableId: table.id,
          childTableId: table.childTableId,
          changeProposalId: table.changeProposalId,
          toUpsertRows: rowsWithProposedChanges
            .filter(row => rowIds.includes(row.id))
            .filter(
              row => row.proposedChange?.type === 'INSERT' || row.proposedChange?.type === 'UPDATE'
            )
            .map(convertDtoToUpsertTableRow),
          toDeleteRows: rowsWithProposedChanges
            .filter(row => rowIds.includes(row.id))
            .filter(row => row.proposedChange?.type === 'DELETE')
            .map(row => ({ id: row.id }))
        });
        get().hideChangeProposalDiff();
        toaster.create({
          title: 'Changes Approved',
          description: 'Changes have been approved',
          type: 'info'
        });
      },
      declineProposedChanges: async (rowIds: string[]) => {
        const table = get().table;
        if (!table.changeProposalId) {
          return;
        }
        set(prev => ({
          ...prev,
          table: {
            ...prev.table,
            rows: table.rows
              .map(row => {
                if (row.proposedChange && rowIds.includes(row.id)) {
                  if (row.proposedChange.type === 'INSERT') {
                    return null;
                  } else if (row.proposedChange.type === 'UPDATE') {
                    const oldValue = row.proposedChange.oldValue;
                    return { ...row, proposedChange: null, ...oldValue };
                  } else if (row.proposedChange.type === 'DELETE') {
                    return { ...row, proposedChange: null };
                  }
                }
                return row;
              })
              .filter(row => row !== null) as TableRowDtoUi[]
          }
        }));
        await declineChangeProposalAction({
          tableId: table.id,
          changeProposalId: table.changeProposalId,
          rowIds
        });
        get().hideChangeProposalDiff();
        toaster.create({
          title: 'Changes Declined',
          description: 'Changes have been declined',
          type: 'info'
        });
      }
    }))
);
