import { logger } from '@company/common/logger';
import { createSupabaseBrowserClient } from '@company/supabase/client/browser';
import {
  REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
  RealtimeChannel,
  RealtimePostgresChangesFilter,
  RealtimePostgresChangesPayload
} from '@company/supabase/types';
import { useProcessingList } from '@company/ui/hooks';
import React from 'react';

type Payload = Record<string, any>;

type Subscription = Omit<
  RealtimePostgresChangesFilter<REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.ALL>,
  'event'
>;

interface UseRealtimeProps<TPayload extends Payload> {
  onSubscribe?: () => Promise<void>;
  channelName: string;
  subscriptions: Subscription[];
  shouldProcessEvent?: (payload: RealtimePostgresChangesPayload<TPayload>) => boolean;
  processEvent: (payload: RealtimePostgresChangesPayload<TPayload>) => void;
  options?: {
    interval?: number;
  };
}

export const useRealtime = <TPayload extends Payload>({
  channelName,
  subscriptions,
  onSubscribe,
  processEvent,
  shouldProcessEvent,
  options
}: UseRealtimeProps<TPayload>) => {
  const channelRef = React.useRef<RealtimeChannel | null>(null);

  const [isSubscribed, setIsSubscribed] = React.useState(false);

  const { addToProcessingList } = useProcessingList<RealtimePostgresChangesPayload<TPayload>>({
    meetsConditions: payload => shouldProcessEvent?.(payload) ?? true,
    onProcess: payload => processEvent(payload),
    options: {
      interval: options?.interval
    }
  });

  React.useEffect(() => {
    if (isSubscribed) {
      return;
    }

    const supabase = createSupabaseBrowserClient();
    channelRef.current = supabase.channel(channelName);

    const handleConnectionStatusChange = (status: string) => {
      logger.info(`Realtime status - "${channelName}": `, status);
      if (status === 'SUBSCRIBED') {
        onSubscribe?.();
        setIsSubscribed(true);
      } else if (status === 'CLOSED' || status === 'CHANNEL_ERROR' || status === 'TIMED_OUT') {
        setIsSubscribed(false);
      }
    };

    for (const subscription of subscriptions) {
      channelRef.current?.on(
        'postgres_changes',
        {
          event: '*',
          schema: subscription.schema,
          table: subscription.table,
          filter: subscription.filter
        },
        payload => addToProcessingList(payload as RealtimePostgresChangesPayload<TPayload>)
      );
    }

    channelRef.current?.subscribe(handleConnectionStatusChange);
  }, [channelName, subscriptions, isSubscribed]);

  React.useEffect(() => {
    return () => {
      channelRef.current?.unsubscribe();
    };
  }, []);
};
