'use client';

import { rowValueToString } from '@company/common/lib';
import { TableRowDto, TableRowLinkedRowType } from '@company/common/types';
import {
  Box,
  Button,
  Center,
  Flex,
  SegmentedControl,
  Spinner,
  Stack,
  Text
} from '@company/ui/components';
import { EyeIcon, EyeOffIcon, GitCompareArrowsIcon, TableIcon } from '@company/ui/icons';
import { Table } from '@components/table';
import { useLinkedRow } from '@components/table/providers/linked-row-provider';
import { useTableStore } from '@components/table/stores/table-store';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import { trpc } from '@server/trpc';
import {
  ILinkedRowRecommendation,
  ILinkedRowRecommendationProcess,
  LinkedRowTableForComparison
} from '@typings/table';
import React from 'react';
import { removeLinkedRowRecommendationAction } from '../../../actions/linked-row';
import { LinkedRowComparison } from './comparison';
import { LinkedRowRecommendationSelector } from './recommended-row';
import { LinkedRowSearch } from './search';

interface LinkedRowBodyProps {
  type: 'SINGLE_ROW' | 'MULTIPLE_ROWS';
  fieldId: string;
  rowId: string;
  linkedTableId: string;
  linkedRowData: TableRowLinkedRowType;
  updateLinkedRows: (linkedRowData: TableRowLinkedRowType) => void;
}

type TabId = 'comparison' | 'table';
type Tab = {
  id: TabId;
  tab: { text: string; icon: React.ReactNode };
  content: React.ReactNode;
};

export const LinkedRowBody = ({
  type,
  fieldId,
  rowId,
  linkedTableId,
  linkedRowData,
  updateLinkedRows
}: LinkedRowBodyProps) => {
  const [activeTabId, setActiveTabId] = React.useState<TabId>('table');
  const { table: currentTable } = useTableStore();
  const { getLinkedRowTable } = useLinkedRow();

  const linkedTable = React.useMemo(() => {
    return getLinkedRowTable({ tableId: linkedTableId });
  }, [linkedTableId]);

  const { data: recommendationProcess, isPending: isPendingRecommendationProcess } =
    trpc.table.linkedRow.getRecommendationProcess.useQuery({ fieldId, rowId });

  if (isPendingRecommendationProcess) {
    return (
      <Center w={'full'} h={'full'} gap={4}>
        <Spinner />
        <Text>
          <Trans>Loading data...</Trans>
        </Text>
      </Center>
    );
  }

  if (!linkedTable) {
    return (
      <Center w={'full'} h={'full'} gap={4}>
        <Text>
          <Trans>Linked table not found</Trans>
        </Text>
      </Center>
    );
  }

  if (
    !recommendationProcess ||
    recommendationProcess.recommendations.filter(rec => !rec.wasRemoved).length === 0
  ) {
    return (
      <Center w={'full'} h={'full'} gap={4}>
        <Text>
          <Trans>No recommendations found</Trans>
        </Text>
      </Center>
    );
  }

  return (
    <InnerLinkedRowBodyWithData
      linkedRowRecommendationProcess={recommendationProcess}
      linkedTable={linkedTable}
      currentTable={currentTable}
      type={type}
      fieldId={fieldId}
      rowId={rowId}
      linkedRowData={linkedRowData}
      updateLinkedRows={updateLinkedRows}
      activeTabId={activeTabId}
      setActiveTabId={setActiveTabId}
    />
  );
};

const InnerLinkedRowBodyWithData = ({
  linkedRowRecommendationProcess,
  linkedTable,
  currentTable,
  type,
  fieldId,
  rowId,
  linkedRowData,
  updateLinkedRows,
  activeTabId,
  setActiveTabId
}: {
  linkedRowRecommendationProcess: ILinkedRowRecommendationProcess;
  currentTable: LinkedRowTableForComparison;
  linkedTable: LinkedRowTableForComparison;
  type: LinkedRowBodyProps['type'];
  fieldId: LinkedRowBodyProps['fieldId'];
  rowId: LinkedRowBodyProps['rowId'];
  linkedRowData: LinkedRowBodyProps['linkedRowData'];
  updateLinkedRows: LinkedRowBodyProps['updateLinkedRows'];
  activeTabId: TabId;
  setActiveTabId: (tabId: TabId) => void;
}) => {
  const { _ } = useLingui();
  const trpcUtils = trpc.useUtils();

  const linkedTablePrimaryFieldId = React.useMemo(() => {
    return linkedTable.fields.find(field => field.isPrimary)?.id;
  }, [linkedTable]);
  const recommendations = React.useMemo(() => {
    return linkedRowRecommendationProcess.recommendations.filter(rec => !rec.wasRemoved);
  }, [linkedRowRecommendationProcess]);
  const selectedRecommendedRowIds = React.useMemo(() => {
    return linkedRowData.linkedRows.map(({ linkedRowId }) => linkedRowId);
  }, [linkedRowData]);

  const [openedRecommendedRow, setOpenedRecommendedRow] =
    React.useState<ILinkedRowRecommendation | null>(
      recommendations.find(rec => selectedRecommendedRowIds.includes(rec.recommendedRowId)) ??
        recommendations[0]!
    );
  const [showDiff, setShowDiff] = React.useState(true);

  const onSelectRecommendedRow = (recommendedRow: ILinkedRowRecommendation) => {
    if (!linkedTablePrimaryFieldId) {
      return;
    }
    onClickRecommendedRow(recommendedRow);
    const newLinkedRow: TableRowLinkedRowType['linkedRows'][0] = {
      linkedRowId: recommendedRow.recommendedRowId,
      primaryFieldValue: rowValueToString(
        recommendedRow.recommendedTableRowData[linkedTablePrimaryFieldId!]!
      ),
      recommendationScore: recommendedRow.score
    };
    if (type === 'SINGLE_ROW') {
      updateLinkedRows({
        ...linkedRowData,
        linkedRows: [newLinkedRow]
      });
    } else {
      updateLinkedRows({
        ...linkedRowData,
        linkedRows: [...linkedRowData.linkedRows, newLinkedRow]
      });
    }
  };

  const onUnselectRecommendedRow = (recommendedRow: ILinkedRowRecommendation) => {
    updateLinkedRows({
      ...linkedRowData,
      linkedRows: linkedRowData.linkedRows.filter(
        row => row.linkedRowId !== recommendedRow.recommendedRowId
      )
    });
  };

  const onRemoveRecommendedRow = async (recommendedRow: ILinkedRowRecommendation) => {
    if (openedRecommendedRow && openedRecommendedRow.id === recommendedRow.id) {
      if (recommendations.length > 0) {
        if (recommendations[0]!.id === recommendedRow.id) {
          setOpenedRecommendedRow(recommendations[1] ?? null);
        } else {
          setOpenedRecommendedRow(recommendations[0] ?? null);
        }
      } else {
        setOpenedRecommendedRow(null);
      }
    }

    const queryPath = trpcUtils.table.linkedRow.getRecommendationProcess;
    queryPath.cancel();
    queryPath.setData({ fieldId, rowId }, prev => {
      if (!prev) {
        return prev;
      }
      return {
        ...prev,
        recommendations: prev.recommendations.map(rec =>
          rec.id === recommendedRow.id ? { ...rec, wasRemoved: true } : rec
        )
      };
    });

    await removeLinkedRowRecommendationAction({ id: recommendedRow.id });
  };

  const onLinkedRowSearchSelect = ({
    recommendation,
    item
  }: {
    recommendation: ILinkedRowRecommendation;
    item: TableRowDto;
  }) => {
    // The selected recommendation doesn't have all data, so we replace it with the existing recommendation
    const existingRecommendation = recommendations.find(rec => rec.id === recommendation.id);
    const queryPath = trpcUtils.table.linkedRow.getRecommendationProcess;
    queryPath.cancel();

    if (existingRecommendation) {
      queryPath.setData({ fieldId, rowId }, prev => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          recommendations: prev.recommendations.map(rec =>
            rec.id === recommendation.id ? { ...rec, wasRemoved: false } : rec
          )
        };
      });
      onSelectRecommendedRow({ ...existingRecommendation, wasRemoved: false });
    } else {
      queryPath.setData({ fieldId, rowId }, prev => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          recommendations: [...prev.recommendations, recommendation]
        };
      });
      onSelectRecommendedRow(recommendation);
    }

    queryPath.invalidate();
  };

  const onClickRecommendedRow = (recommendedRow: ILinkedRowRecommendation) => {
    setOpenedRecommendedRow(recommendedRow);
  };

  React.useEffect(() => {
    setOpenedRecommendedRow(
      selectedRecommendedRowIds.length > 0
        ? (recommendations.find(rec => selectedRecommendedRowIds.includes(rec.recommendedRowId)) ??
            recommendations[0]!)
        : null
    );
  }, [rowId, fieldId]);

  const tabs = React.useMemo((): Tab[] => {
    const comparisonTab: Tab = {
      id: 'comparison',
      tab: { text: _(msg`Comparison`), icon: <GitCompareArrowsIcon boxSize={3.5} /> },
      content: (
        <LinkedRowComparison
          linkedTable={linkedTable}
          recommendation={openedRecommendedRow!}
          currentTable={currentTable}
          showDiff={showDiff}
        />
      )
    };

    if (!linkedTable.childTableId || !openedRecommendedRow) {
      return [comparisonTab];
    }

    return [
      {
        id: 'table',
        tab: { text: _(msg`Table`), icon: <TableIcon boxSize={3.5} /> },
        content: (
          <Table
            tableId={linkedTable.childTableId}
            where={{ row: { parentTableRowIds: [openedRecommendedRow.recommendedRowId] } }}
          />
        )
      },
      comparisonTab
    ];
  }, [openedRecommendedRow, linkedTable, showDiff]);

  return (
    <Flex gap={0} h={'full'} w={'full'}>
      <Box minWidth={'340px'} w={'340px'} h={'full'} borderRightWidth={1}>
        <LinkedRowSearch rowId={rowId} fieldId={fieldId} onSelect={onLinkedRowSearchSelect} />
        <Stack
          gap={0}
          overflowY={'auto'}
          flexGrow={1}
          flexBasis={0}
          flexShrink={1}
          h={'calc(100% - 48px)'}
          pb={2}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
              backgroundColor: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '3px'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent'
            }
          }}
        >
          {linkedRowRecommendationProcess.recommendations
            .filter(rec => !rec.wasRemoved)
            .map(recommendedRow => (
              <LinkedRowRecommendationSelector
                key={recommendedRow.id}
                name={rowValueToString(
                  recommendedRow.recommendedTableRowData[linkedTablePrimaryFieldId!]
                )}
                isFromAiMemory={recommendedRow.isFromAiMemory}
                score={recommendedRow.score}
                isOpen={
                  openedRecommendedRow !== null && openedRecommendedRow.id === recommendedRow.id
                }
                isSelected={selectedRecommendedRowIds.some(
                  selectedRowId => selectedRowId === recommendedRow.recommendedRowId
                )}
                onSelect={() => onSelectRecommendedRow(recommendedRow)}
                onUnselect={() => onUnselectRecommendedRow(recommendedRow)}
                onRemove={() => onRemoveRecommendedRow(recommendedRow)}
                onClick={() => onClickRecommendedRow(recommendedRow)}
              />
            ))}
        </Stack>
      </Box>
      <Box w={'full'}>
        <Stack gap={0} h={'full'}>
          <Box>
            <Flex w={'full'} my={2} h={'32px'} px={4} justify={'space-between'}>
              <SegmentedControl
                value={activeTabId}
                onValueChange={({ value }) => setActiveTabId(value as TabId)}
                items={tabs.map(tab => ({
                  value: tab.id,
                  label: (
                    <Flex gap={1} alignItems={'center'}>
                      {tab.tab.icon}
                      {tab.tab.text}
                    </Flex>
                  )
                }))}
                size="sm"
              />

              {tabs.find(tab => tab.id === activeTabId)?.id === 'comparison' && (
                <Button
                  onClick={() => setShowDiff(!showDiff)}
                  my={1}
                  size={'xs'}
                  variant={'subtle'}
                >
                  {showDiff ? (
                    <>
                      <EyeOffIcon />
                      <Trans>Hide differences</Trans>
                    </>
                  ) : (
                    <>
                      <EyeIcon />
                      <Trans>Show differences</Trans>
                    </>
                  )}
                </Button>
              )}
            </Flex>
          </Box>
          <Box
            h={'full'}
            overflowY={'auto'}
            flexGrow={1}
            flexBasis={0}
            flexShrink={1}
            px={4}
            pb={2}
          >
            {tabs.find(tab => tab.id === activeTabId)?.content}
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
};
