'use client';

import type { LinkedRowRecommendationRun as LinkedRowRecommendationRunType } from '@company/database/core';
import { getLinkedRowRecommendationRunRealtimeChannelName } from '@company/supabase/realtime';
import { useRealtime } from '@hooks/use-realtime';
import { UpdateTableRow } from '../types';
import { trpc } from '@server/trpc';
import { toUTCDate } from '@company/common/lib';

type LinkedRowRecommendationRun = Omit<
  LinkedRowRecommendationRunType,
  'createdOn' | 'updatedOn'
> & {
  createdOn: string;
  updatedOn: string;
};

interface UseSubscribeToLinkedRowChangesProps {
  tableId: string;
  updateRows: (
    rows: UpdateTableRow[],
    options?: {
      shouldSaveChanges: boolean;
    }
  ) => void;
}

export const useSubscribeToLinkedRowChanges = ({
  tableId,
  updateRows
}: UseSubscribeToLinkedRowChangesProps) => {
  const trpcUtils = trpc.useUtils();

  useRealtime<LinkedRowRecommendationRun>({
    channelName: getLinkedRowRecommendationRunRealtimeChannelName(tableId),
    onSubscribe: async () => {},
    subscriptions: [
      {
        schema: 'public',
        table: 'LinkedRowRecommendationRun',
        filter: `tableId=eq.${tableId}`
      }
    ],
    processEvent: async payload => {
      if (payload.eventType === 'UPDATE') {
        const { rows } = await trpcUtils.table.linkedRow.getUpdatedLinkedRowValuesLaterThan.fetch({
          runId: payload.new.id,
          tableId,
          date: toUTCDate(payload.new.updatedOn)
        });

        void updateRows(rows, { shouldSaveChanges: false });
      }
    }
  });
};
