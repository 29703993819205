'use client';

import { FieldDto, TableRowFilter } from '@company/common/types';
import { Center, Spinner, Text } from '@company/ui/components';
import { Trans } from '@lingui/react/macro';
import { trpc } from '@server/trpc';
import { FieldToLinkedRowRecommendationData, TableDtoUi } from '@typings/table';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { ErrorBoundary } from '../error-boundry';
import { TableHeader } from './header';
import { useSubscribeToLinkedRowChanges, useSubscribeToProposedChanges } from './hooks';
import { ChildTableProvider } from './providers/child-table-provider';
import { LinkedRowTableProvider } from './providers/linked-row-provider';
import { TableQueryStoreProvider, useTableQueryStore } from './stores/table-query-store';
import { TableStoreProvider, useTableStore } from './stores/table-store';
import { TableHeaderProps } from './types';
import { GridView } from './views';

interface TableConfig {}

type TableProps = {
  changeProposalId?: string | null;
  config?: TableConfig;
  header?: TableHeaderProps;
} & (
  | {
      tableId: string;
      viewId?: string;
      where?: {
        row?: TableRowFilter;
      };
    }
  | { table: TableDtoUi }
);

export const Table = ({ changeProposalId, config, header, ...tableProps }: TableProps) => {
  const isTableDto = 'table' in tableProps;
  const { tableId, viewId, where } = isTableDto
    ? { tableId: undefined, viewId: undefined, where: undefined }
    : tableProps;
  const queryParams = {
    tableId: tableId!,
    viewId,
    changeProposalId,
    where: {
      row: where?.row
    }
  };

  const { data: table, isLoading: isLoadingTable } = trpc.table.getById.useQuery(queryParams, {
    placeholderData: isTableDto ? tableProps.table : undefined,
    enabled: !isTableDto
  });
  const { data: childTable } = trpc.table.getById.useQuery(
    {
      tableId: table?.childTableId!,
      viewId: null,
      changeProposalId: null,
      where: {}
    },
    {
      enabled: !!table?.childTableId
    }
  );

  const isLinkedRowFieldInActiveView = (field: FieldDto) =>
    field.type === 'LINKED_ROW' &&
    table?.activeView.state.fields.map(field => field.id).includes(field.id);

  const linkedRowTableIds = table?.fields
    .filter(isLinkedRowFieldInActiveView)
    .map(field => field.linkedRowConfig!.tableId);
  const currentTableLinkedRowFieldIds = table?.fields
    .filter(isLinkedRowFieldInActiveView)
    .map(field => field.id);

  const { data: linkedRowTables } = trpc.table.getByIds.useQuery(
    {
      tables: (linkedRowTableIds ?? []).map(tableId => ({
        tableId,
        viewId: null,
        changeProposalId: null,
        where: {}
      }))
    },
    { enabled: !!linkedRowTableIds }
  );
  const { data: fieldToLinkedRowRecommendationData } =
    trpc.table.linkedRow.getMinimalRecommendationInfoForFields.useQuery(
      {
        fieldIds: currentTableLinkedRowFieldIds ?? []
      },
      { enabled: !!currentTableLinkedRowFieldIds }
    );

  if (isLoadingTable && !isTableDto) {
    return <LoadingTable />;
  }

  if (!table) {
    return <Trans>Table not found</Trans>;
  }

  return (
    <ErrorBoundary>
      <TableStoreProvider initialValue={{ table }}>
        <TableQueryStoreProvider
          initialValue={{ tables: linkedRowTables ? [table, ...linkedRowTables] : [table] }}
        >
          <TableInner
            childTable={childTable}
            linkedRowTables={linkedRowTables}
            fieldToLinkedRowRecommendationData={fieldToLinkedRowRecommendationData}
            config={config}
            header={header}
          />
        </TableQueryStoreProvider>
      </TableStoreProvider>
    </ErrorBoundary>
  );
};

interface TableInnerProps {
  childTable: TableDtoUi | null | undefined;
  linkedRowTables: TableDtoUi[] | undefined;
  fieldToLinkedRowRecommendationData: FieldToLinkedRowRecommendationData | undefined;
  config?: TableConfig;
  header?: TableHeaderProps;
}

const TableInner = ({
  childTable,
  linkedRowTables,
  fieldToLinkedRowRecommendationData,
  config,
  header
}: TableInnerProps) => {
  const { table, updateRows } = useTableStore(
    useShallow(state => ({
      table: state.table,
      updateRows: state.updateRows
    }))
  );
  const { updateTables } = useTableQueryStore(
    useShallow(state => ({
      updateTables: state.updateTables
    }))
  );

  useSubscribeToLinkedRowChanges({
    tableId: table.id,
    updateRows
  });

  useSubscribeToProposedChanges({
    changeProposalId: table.changeProposalId,
    addProposedChangesToTableRows: () => {}
  });

  React.useEffect(() => {
    if (linkedRowTables) {
      updateTables([table, ...linkedRowTables]);
    }
  }, [linkedRowTables, updateTables, table]);

  return (
    <ChildTableProvider childTable={childTable}>
      <LinkedRowTableProvider
        linkedRowTables={linkedRowTables}
        fieldToLinkedRowRecommendationData={fieldToLinkedRowRecommendationData}
      >
        <View config={config ?? {}} header={header} />
      </LinkedRowTableProvider>
    </ChildTableProvider>
  );
};

const LoadingTable = () => {
  return (
    <Center h={'full'} gap={4}>
      <Spinner />
      <Text>
        <Trans>Loading data...</Trans>
      </Text>
    </Center>
  );
};

interface ViewProps {
  config: TableConfig;
  header?: TableHeaderProps;
}

export const View = (props: ViewProps) => {
  const activeView = useTableStore(useShallow(state => state.table.activeView));
  const viewType = activeView?.type;

  const header = React.useMemo(() => <TableHeader header={props.header} />, [props.header]);

  const getViewComponent = () => {
    switch (viewType) {
      case 'GRID':
        return <GridView header={header} />;
      default:
        return null;
    }
  };

  return getViewComponent();
};
