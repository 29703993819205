import { CustomCellEditorProps } from '@ag-grid-community/react';
import { Box, Flex } from '@company/ui/components';
import { ViewField } from '@components/table/types';
import { TableRowDtoUi } from '@typings/table';
import React from 'react';
import { CheckboxCell } from './checkbox';
import { DateCell } from './date';
import { DateTimeCell } from './date-time';
import { FileAttachmentCell } from './file-attachment';
import { LinkedRowCell } from './linked-row';
import { LongTextCell } from './long-text';
import { MultiSelectCell } from './multi-select';
import { NumberCell } from './number';
import { ObjectCell } from './object';
import { SingleLineTextCell } from './single-line-text';
import { SingleSelectCell } from './single-select';
import { CellEditorProps, CellRendererProps } from './types';

export const CellRenderer = React.memo((props: CellRendererProps) => {
  const fieldTypeToCell: Record<ViewField['type'], React.ReactElement> = React.useMemo(
    () => ({
      LONG_TEXT: <LongTextCell {...props} />,
      SHORT_TEXT: <SingleLineTextCell {...props} />,
      NUMBER: <NumberCell {...props} />,
      SINGLE_SELECT: <SingleSelectCell {...props} />,
      MULTI_SELECT: <MultiSelectCell {...props} />,
      DATE: <DateCell {...props} />,
      DATE_TIME: <DateTimeCell {...props} />,
      CHECKBOX: <CheckboxCell {...props} />,
      FILE_ATTACHMENT: <FileAttachmentCell {...props} />,
      LINKED_ROW: <LinkedRowCell {...props} />,
      OBJECT: <ObjectCell {...props} />
    }),
    [props]
  );
  const cell = React.useMemo(() => fieldTypeToCell[props.field.type], [props, fieldTypeToCell]);

  return (
    <Flex align={'center'} h={'full'} w={'full'} fontSize={'xs'}>
      {cell}
    </Flex>
  );
});

interface InputCellEditorProps extends CustomCellEditorProps<TableRowDtoUi> {
  field: ViewField;
  row: TableRowDtoUi;
}

export const CellEditor: React.FC<InputCellEditorProps> = props => {
  const propsWithValue: CellEditorProps = React.useMemo(
    () => ({
      ...props,
      value: props.data[props.field.id],
      rowId: props.data.id
    }),
    [props]
  );

  const fieldTypeToCell: Record<ViewField['type'], React.ReactElement> = React.useMemo(
    () => ({
      LONG_TEXT: <></>,
      SHORT_TEXT: <></>,
      NUMBER: <></>,
      SINGLE_SELECT: <></>,
      MULTI_SELECT: <></>,
      DATE: <></>,
      DATE_TIME: <></>,
      CHECKBOX: <></>,
      FILE_ATTACHMENT: <></>,
      LINKED_ROW: <></>,
      OBJECT: <></>
    }),
    [propsWithValue]
  );
  const cell = React.useMemo(
    () => fieldTypeToCell[propsWithValue.field.type],
    [propsWithValue.field.type, fieldTypeToCell]
  );

  return (
    <Box w={'full'} h={'full'}>
      <Flex align={'center'} h={'full'} w={'full'} fontSize={'xs'}>
        {cell}
      </Flex>
    </Box>
  );
};
